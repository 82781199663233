import { useEffect, useState } from "react"
import "./login.css";
import { LogoIcon } from "../../utils/logoIcon";
import { Login as TryLogin } from "../../Api";
import { useNavigate } from "react-router-dom";

interface Props {
    userData: object;
    setUserData(userData: object): void;
    showLoading(value: boolean): void;
}

export function Login(props: Props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (props.userData !== null) {
            navigate("/");
        }
    }, [props.userData]);

    async function handleLogin() {
        setErrorMessage('');
        props.showLoading(true);
        const result = await TryLogin(email, password);
        props.showLoading(false);
        if (!result) {
            setErrorMessage('Dados incorretos, tente novamente.');
            return;
        }

        props.setUserData(result);
        navigate("/");
    }

    return <div className="login">
        <form className="login-form">
            <div className="login-form-box">
                <div className="login-form-header">
                    {LogoIcon()}
                </div>
                <div className="login-description">
                    <span>Insira e-mail e senha para entrar</span>
                </div>
                <div className="input-box">
                    <input
                        className="input-email"
                        type="email"
                        placeholder="Insira uma email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <div className="input-box">
                    <input
                        className="input-password"
                        type="password"
                        placeholder="Insira uma password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="login-error-message">
                    <span>{errorMessage}</span>
                </div>
                <div className="input-box">
                    <button className="login-button" type="button" onClick={() => handleLogin()}>Entrar</button>
                </div>
            </div>
        </form>
    </div>
}