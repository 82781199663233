import { useEffect, useState } from "react";
import "./articleList.css";
import { Delete, GetByFilter } from "../../Api";
import { ArticleFilter } from "../../entities/articleFilter";
import { ArticleEntity } from "../../entities/articleEntity";
import { convertDate } from "../../utils/dateExtension";

interface Props {
    showLoading(value: boolean): void
}

export function ArticleList(props: Props) {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 800) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    const [articleList, setArticleList] = useState([]);
    useEffect(() => {
        getContent();
        handleResize();
        window.addEventListener("resize", handleResize)
    }, []);

    async function getContent() {
        props.showLoading(true);

        const filter = {
            latest: true,
            limit: 10,
        } as ArticleFilter;
        const result = await GetByFilter(filter);
        props.showLoading(false);
        setArticleList(result);
    }

    async function remove(article: ArticleEntity) {
        const confirmationResult = await window.Swal.fire({
            title: "Deseja realmente excluir esta publicação?",
            text: article.title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#6a2c70",
            cancelButtonColor: "#898989",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Excluir"
        }).then((result: any) => result);

        if (!confirmationResult.isConfirmed)
            return;

        const result = await Delete(article._id);
        if (!result[0]) {
            props.showLoading(false);
            window.Swal.fire({
                title: "Erro!",
                text: result[1],
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#6a2c70"
            });

            return;
        }

        props.showLoading(false);
        window.Swal.fire({
            title: "Sucesso!",
            text: "Publicação excluida com sucesso",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#6a2c70"
        });
    }

    return <div className="container">
        <div className="article-list">
            <h2>Lista de postagens</h2>

            <div className="card">
                <div className="card-title">
                    Últimos posts
                </div>
                <div className="card-content">
                    <table>
                        <thead>
                            <tr>
                                {!isMobile && <th>Id</th>}
                                <th>Título</th>
                                <th>Data</th>
                                <th>Categoria</th>
                                <th>Remover</th>
                            </tr>
                        </thead>
                        <tbody>
                            {articleList.map((element: ArticleEntity, index) => (
                                <tr key={index}>
                                    {!isMobile && <td>{element._id}</td>}
                                    <td>{isMobile ? `${element.title.substring(0, 30)}...` : element.title}</td>
                                    <td>{convertDate(element.createdAt)}</td>
                                    <td>{element.category}</td>
                                    <td onClick={() => remove(element)}>
                                        <button type="button"><i className="material-symbols-outlined">delete</i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}