import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LogoIcon } from "../../utils/logoIcon";
import { Logoff } from "../../Api";
import "./header.css";
import { UserEntity } from "../../entities/userEntity";

interface Props {
    categories: string[];
    userData: UserEntity;
}

export function Header(props: Props) {
    const [menuState, setMenuState] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

    }, [window.location.href, props.userData]);

    return <header className="header">
        <button className="open-menu-btn" onClick={() => setMenuState(true)}>
            <i className="material-symbols-outlined">menu</i>
        </button>
        <div className="user-fields">
            <span>{props.userData.name}</span>
            <button className="user-btn" onClick={() => Logoff()}>
                <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="" />
            </button>
        </div>
        <div className={menuState ? "menu active" : "menu"}>
            <div className="menu-header">
                <div className="logo">
                    {LogoIcon()}
                </div>
                <button className="close-menu-btn" onClick={() => setMenuState(false)}>
                    <i className="material-symbols-outlined">close</i>
                </button>
            </div>
            <ul className="menu-items">
                <li className={window.location?.href.includes("/home") ? "active" : ""} onClick={() => setMenuState(false)}>
                    <Link to="/home">Início</Link>
                </li>
                <li className={window.location?.href.includes("/novo-post") ? "active" : ""} onClick={() => setMenuState(false)}>
                    <Link to="/novo-post">Nova postagem</Link>
                </li>
                <li className={window.location?.href.includes("/posts") ? "active" : ""} onClick={() => setMenuState(false)}>
                    <Link to="/posts">Postagens</Link>
                </li>
            </ul>
        </div>
    </header>
}