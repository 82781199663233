import { UserEntity } from "../entities/userEntity";

export function parseJwt(token: string): UserEntity {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var object = JSON.parse(jsonPayload);
        return object.data;
    } catch (ex) {
        console.log("Erro na leitura de token JWT", ex);
        return null;
    }
}