import { useEffect, useState } from "react";
import { CreateArticleRequest } from "../../entities/createArticleRequest";
import { Create } from "../../Api";
import { PreviewModal } from "../../components/previewModal/previewModal";
import { ArticleEntity } from "../../entities/articleEntity";

interface Props {
    showLoading(value: boolean): void
}

export function Creator(props: Props) {
    const [category, setCategory] = useState('');
    const [tag, setTag] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [url, setUrl] = useState('');
    const [author, setAuthor] = useState('Camila Busnello');
    const [banner, setBanner] = useState('');
    const [content, setContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [articlePreview, setArticlePreview] = useState<ArticleEntity>();
    var quill: any = null;

    useEffect(() => {
        setQuill();
    }, []);

    function setQuill() {
        if (quill)
            return;

        const toolbarOptions = [['bold', 'italic', 'link', 'image']];
        quill = new window.Quill('.input-content', {
            theme: 'snow',
            formats: ['italic', 'bold', 'image', 'link'],
            modules: {
                toolbar: toolbarOptions
            }
        });

        quill.getModule("toolbar").addHandler("image", () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
            input.onchange = async function () {
                const file = input.files[0];
                let reader = new FileReader();

                reader.onload = async function () {
                    const range = quill.getSelection();

                    const { value: description } = await window.Swal.fire({
                        title: "Insira uma descrição para esta imagem",
                        input: "text",
                        inputLabel: "Descrição da imagem",
                        showCancelButton: true,
                        inputValidator: (value: any) => {
                            if (!value) {
                                return "You need to write something!";
                            }
                        }
                    });
                    if (description) {
                        quill.insertEmbed(range.index, 'image', reader.result);
                        quill.formatText(range.index, 1, 'alt', description);
                    }
                }
                reader.readAsDataURL(file);
            };
        });
    }

    function setTitleValue(value: string) {
        setTitle(value);
        setUrl(value.toLowerCase().trim().normalize("NFD").replace(/[^a-zA-Z\s]/g, "").replaceAll(" ", "-"));
    }

    async function publishArticle() {
        if (!category || category.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar uma categoria para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!tag || tag.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar uma tag para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!title || title.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar um título para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!subTitle || subTitle.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar um subtítulo para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!url || url.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar uma url de busca para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!author || author.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar o nome do autor para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        if (!banner || banner.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar uma url de banner para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        var contentText = document.querySelector(".ql-editor")?.innerHTML || '';
        contentText = contentText.replaceAll("<p><br></p>", "").replaceAll("<p></p>", "");

        if (!contentText || contentText.length === 0) {
            window.Swal.fire({
                title: "Opa!",
                text: "Necessário informar o conteúdo para a publicação.",
                icon: "info",
                confirmButtonText: "OK"
            });
            return;
        }

        const confirmationResult = await window.Swal.fire({
            title: "Deseja realmente realizar esta publicação?",
            text: "Já verificou se está tudo OK?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#6a2c70",
            cancelButtonColor: "#898989",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Postar"
        }).then((result: any) => result);
        
        if(!confirmationResult.isConfirmed)
            return;

        let count = 1;
        var contentImages: string[] = [];

        document.querySelectorAll(".article-body > .ql-editor > p > img").forEach(x => {
            let src = x.getAttribute("src");
            contentImages.push(src);
            contentText = contentText.replace(src, `[[image-${count}]]`);
            count++;
        });

        props.showLoading(true);
        let request: CreateArticleRequest = {
            title: title,
            subTitle: subTitle,
            tag: tag,
            url: url,
            category: category,
            author: author,
            urlImg: banner,
            content: contentText,
            contentImageList: contentImages
        }

        const result = await Create(request);
        if (!result[0]) {
            props.showLoading(false);
            window.Swal.fire({
                title: "Erro!",
                text: result[1],
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#6a2c70"
            });

            return;
        }
        
        props.showLoading(false);
        window.Swal.fire({
            title: "Sucesso!",
            text: "Publicação realizada com sucesso",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#6a2c70"
        });
    }

    function openModal() {
        var contentText = document.querySelector(".ql-editor")?.innerHTML || '';
        contentText = contentText.replaceAll("<p><br></p>", "").replaceAll("<p></p>", "");

        const article = {
            category: category,
            tag: tag,
            title: title,
            subTitle: subTitle,
            url: url,
            author: author,
            urlImg: banner,
            content: contentText
        } as ArticleEntity;

        setArticlePreview(article);
        setShowModal(true);
    }

    return (
        <div className="container">
            <div className="article-creation">
                <h2>Nova postagem</h2>
                <form className="create-article-form">
                    <div className="row">
                        <div className="card">
                            <div className="card-title">
                                Informações principais
                            </div>
                            <div className="row prevent">
                                <div className="input-box col-6">
                                    <label>Categoria</label>
                                    <select
                                        className="input-category"
                                        value={category}
                                        onChange={(event) => setCategory(event.target.value)}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="Famosos">Famosos</option>
                                        <option value="TV">TV</option>
                                        <option value="Mundo">Mundo</option>
                                    </select>
                                </div>
                                <div className="input-box col-6">
                                    <label>Tag</label>
                                    <input
                                        className="input-tag"
                                        type="text"
                                        placeholder="Insira uma tag"
                                        value={tag}
                                        onChange={(event) => setTag(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="input-box">
                                <label>Título</label>
                                <input
                                    className="input-title"
                                    type="text"
                                    placeholder="Insira um título"
                                    value={title}
                                    onChange={(event) => setTitleValue(event.target.value)}
                                />
                            </div>
                            <div className="input-box">
                                <label>Sub-título</label>
                                <input
                                    className="input-subtitle"
                                    type="text"
                                    placeholder="Insira um subtítulo"
                                    value={subTitle}
                                    onChange={(event) => setSubTitle(event.target.value)}
                                />
                            </div>
                            <div className="input-box">
                                <label>Url da notícia</label>
                                <input
                                    className="input-url"
                                    type="text"
                                    placeholder="Insira uma url"
                                    value={url}
                                    onChange={(event) => setUrl(event.target.value)}
                                />
                            </div>
                            <div className="row">
                                <div className="input-box">
                                    <label>Autor(a)</label>
                                    <input
                                        className="input-author"
                                        type="text"
                                        placeholder="Insira o nome do Author(a)"
                                        value={author}
                                        onChange={(event) => setAuthor(event.target.value)}
                                    />
                                </div>
                                <div className="input-box">
                                    <label>Url Banner</label>
                                    <input
                                        className="input-url-banner"
                                        type="text"
                                        placeholder="Insira a url do banner"
                                        value={banner}
                                        onChange={(event) => setBanner(event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                Conteúdo da publicação
                            </div>
                            <div className="input-box">
                                <label>Conteúdo</label>
                                <div className="input-content-box">
                                    <div className="input-content article-body">
                                        {/* ... */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row prevent card-buttons">
                    <button
                        className="new-post-btn light"
                        type="button"
                        onClick={() => openModal()}
                    >
                        Preview
                    </button>
                    <button
                        className="new-post-btn"
                        type="button"
                        onClick={() => publishArticle()}
                    >
                        Publicar
                    </button>
                </div>
            </div>
            <PreviewModal article={articlePreview} show={showModal} setShowModal={setShowModal} />
        </div>
    );
}