import "./loading.css";

interface Props {
    show: boolean;
}

export function Loading(props: Props) {
    return <div className={props.show ? "loading show" : "loading"}>
        <div className="loading-background"></div>
        <div className="spinner"></div>
    </div>
}