import { ArticleEntity } from "../../entities/articleEntity";
import "./previewModal.css";

interface Props {
    article: ArticleEntity;
    show: boolean;
    setShowModal(value: boolean): void;
}

export function PreviewModal(props: Props) {
    return <div className={props.show ? "modal show" : "modal"}>
        <div className="modal-block-touch"></div>
        <div className="modal-content">
            {props.article &&
                <>
                    <div className="modal-header">
                        <h3>Pré visualização da publicação</h3>
                        <button className="close-modal-btn" onClick={() => props.setShowModal(false)}>
                            <i className="material-symbols-outlined">close</i>
                        </button>
                    </div>
                    <div className="article-content-box">
                        <div className="article-content">
                            <div className="article-header">
                                <p className="article-tag">{props.article.category}</p>
                                <h2 className="article-title">{props.article.title}</h2>
                                <p className="article-subtitle">{props.article.subTitle}</p>
                                <div className="article-info">
                                    <p className="article-author">{props.article.author}</p>
                                    {props.article.author &&
                                        <p className="article-date">04/04/2024 às 08:57. Atualizado em 04/04/2024 às 08:57</p>
                                    }
                                </div>
                                <div className="article-banner">
                                    {props.article.urlImg && <img src={props.article.urlImg} alt={props.article.title} />}
                                </div>
                            </div>
                            <div className="article-body" dangerouslySetInnerHTML={{ __html: props.article.content }}>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    </div>
}