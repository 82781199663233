import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from './components/loading/loading';
import { Header } from './components/header/header';
import { Home } from './pages/home/home';
import { ArticleList } from './pages/articleList/articleList';
import { Creator } from './pages/creator/creator';
import { CheckToken } from './Api';
import { AppSettings } from './settings/appSettings';
import { Login } from './pages/login/login';
import './App.css';
import { parseJwt } from './helpers/jwtHelper';
import { UserEntity } from './entities/userEntity';

declare global {
  interface Window {
    Quill?: any;
    Swal?: any;
  }
}

function App(props: any) {
  const categories = ["Em alta", "Últimas", "TV", "Famosos", "Mundo"];
  const [showLoading, setShowLoading] = useState(false);
  const [userData, setUserData] = useState<UserEntity>(null);

  useEffect(() => {
    checkToken();
  }, [localStorage.getItem(AppSettings.LocalStorageTokenName)]);

  async function checkToken() {
    const token = localStorage.getItem(AppSettings.LocalStorageTokenName) || '';
    if (token.length === 0) {
      setUserData(null);
      return;
    }

    const isTokenValid = await CheckToken(token);
    if (!isTokenValid) {
      setUserData(null);
      return;
    }

    const userData = parseJwt(token);
    if(!userData) {
      setUserData(null);
      return;
    }

    setUserData(userData);
  }

  return (

    <BrowserRouter>
      {userData && <Header categories={categories} userData={userData} />}
      {!userData && <Navigate to="/login" />}
      <Routes>
        <Route path="/login" element={<Login {...props} userData={userData} setUserData={setUserData} showLoading={setShowLoading} />} />
        <>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home {...props} showLoading={setShowLoading} />} />
          <Route path="/novo-post" element={<Creator {...props} showLoading={setShowLoading} />} />
          <Route path="/posts" element={<ArticleList {...props} showLoading={setShowLoading} />} />
          <Route path="/posts" element={<ArticleList {...props} showLoading={setShowLoading} />} />
        </>
      </Routes>
      <Loading show={showLoading} />
    </BrowserRouter>
  );
}

export default App;
