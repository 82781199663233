import { ArticleEntity } from "./entities/articleEntity";
import { ArticleFilter } from "./entities/articleFilter";
import { CreateArticleRequest } from "./entities/createArticleRequest";
import { UserEntity } from "./entities/userEntity";
import { parseJwt } from "./helpers/jwtHelper";
import { AppSettings } from './settings/appSettings';

export async function Login(email: string, password: string): Promise<UserEntity> {
    const result = await fetch(`${AppSettings.ApiServiceUrl}/user/login`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: email, password: password })
    });

    if (result.status === 200) {
        const body = await result.json();
        setToken(body.token);
        return parseJwt(body.token);
    }

    removeToken();
    return null;
}

export async function CheckToken(token: string): Promise<boolean> {
    const result = await fetch(`${AppSettings.ApiServiceUrl}/user/checktoken`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: token })
    });

    if (result.status === 200) {
        return true;
    }

    removeToken();
    return false;
}

export async function Logoff() {
    removeToken();
    window.location.href = "/";
    return true;
}

export async function GetByFilter(filter: ArticleFilter): Promise<Array<ArticleEntity>> {
    var requestFilter: any = {
        limit: filter.limit ?? 1
    }

    if (filter.topArticles) requestFilter.topArticles = filter.topArticles;
    if (filter.latest) requestFilter.latest = filter.latest;
    if (filter.mostRead) requestFilter.mostRead = filter.mostRead;
    if (filter.category) requestFilter.category = filter.category;
    if (filter.searchText) requestFilter.searchText = filter.searchText;
    if (filter.page || filter.page === 0) requestFilter.page = filter.page;

    const query = new URLSearchParams(requestFilter).toString();
    return await fetch(`${AppSettings.ApiServiceUrl}/articles?${query}`).then(x => x.status === 200 ? x.json() : []);
}

export async function GetByUrl(url: string): Promise<ArticleEntity> {
    return await fetch(`${AppSettings.ApiServiceUrl}/articles/${url}`).then(x => x.status === 200 ? x.json() : null);
}

export async function Create(request: CreateArticleRequest): Promise<[boolean, string]> {
    const result = await fetch(`${AppSettings.ApiServiceUrl}/articles`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": getToken()
        },
        body: JSON.stringify(request)
    });

    if (result.status === 200)
        return [true, null];

    if (result.status === 401)
        removeToken();

    var json = await result.json().catch(x=> null);

    return [false, json?.error ?? "Não foi possível realizar sua publicação."];
}

export async function Delete(id: string): Promise<[boolean, string]> {
    const result = await fetch(`${AppSettings.ApiServiceUrl}/articles/${id}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": getToken()
        }
    });

    if (result.status === 200)
        return [true, null];

    if (result.status === 401)
        removeToken();

    if (result.status === 404)
        return [false, "Publicação não encontrada."]

    var json = await result.json().catch(x=> null);

    return [false, json?.error ?? "Não foi possível realizar sua publicação."];
}

function setToken(token: string) {
    return localStorage.setItem(AppSettings.LocalStorageTokenName, token);
}

function getToken() {
    return "Bearer " + localStorage.getItem(AppSettings.LocalStorageTokenName) ?? '';
}

function removeToken() {
    localStorage.removeItem(AppSettings.LocalStorageTokenName);
}