import { useEffect, useState } from "react";
import { ArticleEntity } from "../../entities/articleEntity";
import "./home.css";

interface Props {
    showLoading(value: boolean): void
    mostReadArticles: ArticleEntity[]
}

export function Home(props: Props) {
    useEffect(() => {
    }, []);

    return <div className="container">
        <div className="home">
            <h2>Bem vindo</h2>
        </div>
    </div>
}